<template>
    <div v-if="changemail" class=" anime transform top-0 left-0 w-full h-full fixed "  :class="changemail ? 'translate-x-0' : '-translate-x-full'">
        <div class="container mx-auto bg-white rounded w-10/12 sm:w-5/12 mt-32">
            <div class=" container mx-auto p-8 sm:px-12">

            <form @submit.prevent="submitToConnect" class=" mb-8 sm:mb-32">
                <div class=" flex flex-col items-center justify-center">
                    <span class="mt-4 text-xl sm:text-3xl text-center font-semibold">{{ $t("account.mailChange",{c: 'C'})}}</span>
                </div>

                <div class="mt-6">
                    <label class="text-base" for="email">Email</label>
                    <br>
                    <input 
                        class="p-3 h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded w-full py-2 leading-tight focus:outline-none focus:bg-white" 
                        id="email" 
                        name="email" 
                        type="text"
                        v-validate="'required|email'"
                        :class="{ 'border border-tex-2': submitted && errors.has('email') }">
                </div>
                <div class="mt-6">
                    <label class="text-base " for="pwd">{{ $t("sign.pwd")}}</label>
                    <br>
                    <div 
                        class=" bg-white h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded flex flex-row"
                        :class="{ 'border border-tex-2': submitted && errors.has('pwd') }">
                        <input 
                            class="p-3 w-11/12 py-2 leading-tight focus:outline-none focus:bg-white" 
                            id="pwd" 
                            name="pwd" 
                            :type="pwdType"
                            v-validate="'required|min:8'">
                        <Iconeye 
                            class="m-2" 
                            @click="switchPwdType"
                            v-if="pwdType === 'password'"/>
                        <Iconeyeoff 
                            class="m-2" 
                            @click="switchPwdType"
                            v-if="pwdType === 'text'"/>
                    </div>
                </div>

                <button class="mt-6 text-white h-minput bg-kbutton-1 hover:bg-kbutton-2 active:border active:border-kbutton-3 w-full text-sm sm:text-base rounded focus:outline-none">{{ $t("counterRecharge.btn")}}</button>
                
            </form>
        </div>
        </div>
    </div>
</template>

<script>
import Iconeye from '../../assets/svg/icons/eye.svg?inline';
import Iconeyeoff from '../../assets/svg/icons/eyeoff.svg?inline';
//import Iconclose from '../../assets/svg/dashbIcons/close.svg?inline';
export default {
    components:{
        //Iconclose,
        Iconeye,
        Iconeyeoff
    },
    props: {
        changemail:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            pwdType: 'password',
            submitted: false,
            changemailData: this.changemail,
            valider: false,
        }
    },
    
    methods: {
        switchPwdType(){
            this.pwdType = this.pwdType === 'password' ? 'text' : 'password';
        },
        submitToConnect(){
            this.submitted = true;
            this.$validator.validate().then(valid => {
            if (valid) {
            this.$router.push("/dashboard");
            }
            });
        },
        passchangemail(){
            this.changemailData = false;
            this.$emit('listenchangemail',this.changemailData);
        },
    },
    watch: {
    changemail: {
      immediate: true,
      handler(changemail) {
       if (process.client) {
          if (changemail) document.body.style.setProperty("overflow", "hidden");
          else document.body.style.removeProperty("overflow");
        }
      }
    }
    }
}
</script>

<style scoped>
.anime{
    animation: modal 400ms ease-out forwards;
}
@keyframes modal {
    0% {
        background-color: rgba(0, 0, 0, 0);
    }
    25% {
        background-color: rgba(0, 0, 0, 0.15);
    }
    50% {
        background-color: rgba(0, 0, 0, 0.3);
    }
    75% {
        background-color: rgba(0, 0, 0, 0.5);
    }
    100% {
        background-color: rgba(0, 0, 0, 0.75);
    }
}
</style>