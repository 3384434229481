<template>
    <div v-if="changepwd" class=" anime transform top-0 left-0 w-full h-full fixed "  :class="changepwd ? 'translate-x-0' : '-translate-x-full'">
        <div class="container mx-auto bg-white rounded w-10/12 sm:w-7/12 lg:w-5/12 mt-32">
        <span class="flex justify-end p-3"><Iconclose @click="passchangepwd"/></span>
            <div class=" container mx-auto p-12 pt-0">
                <form @submit.prevent="submitToChangePwd" class=" ">
                    <div class=" flex flex-col items-center justify-center">
                        <span class="mt-4 text-xl lg:text-3xl text-center font-semibold">{{ $t("account.pwdChange",{c: 'C'})}}</span>
                    </div>

                    <div class="mt-6">
                        <label class="text-base " for="pwd">{{ $t("sign.pwd")}}</label>
                        <br>
                        <div 
                            class=" bg-white h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded flex flex-row"
                            :class="{ 'border-tex-2': submitted && errors.has('pwd') }">
                            <input 
                                class="p-3 w-11/12 py-2 leading-tight focus:outline-none focus:bg-white" 
                                id="pwd" 
                                name="pwd" 
                                v-model="oldPassword"
                                :type="oldPwdShow ? 'text' : 'password'"
                                v-validate="'required|min:8'">
                            <Iconeye 
                                class="m-2" 
                                @click="oldPwdShow = !oldPwdShow"
                                v-if="!oldPwdShow"/>
                            <Iconeyeoff 
                                class="m-2" 
                                @click="oldPwdShow = !oldPwdShow"
                                v-if="oldPwdShow"/>
                        </div>
                        <span v-show="wrongPwd" class="text-tex-2">{{ $t("sign.invalidPwd")}}</span>
                        
                    </div>

                    <div class="mt-6">
                        <label class="text-base " for="newpwd">{{ $t("sign.pwdNew")}}</label>
                        <br>
                        <div 
                            class=" bg-white h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded flex flex-row"
                            :class="{ ' border-tex-2': submitted && errors.has('newpwd') }">
                            <input 
                                class="p-3 w-11/12 py-2 leading-tight focus:outline-none focus:bg-white" 
                                id="newpwd" 
                                name="newpwd" 
                                 :type="pwdShow ? 'text' : 'password'"
                                v-model="newPassword"
                                v-validate="'required|min:8|number|charac|upCase'"
                                ref="newpwd">
                            <Iconeye 
                                class="m-2" 
                                @click="pwdShow = !pwdShow"
                                v-if="!pwdShow"/>
                            <Iconeyeoff 
                                class="m-2" 
                                @click="pwdShow = !pwdShow"
                                v-if="pwdShow"/>
                        </div>
                    </div>

                    <div class="grid grid-rows-2 grid-flow-col gap-2 mt-3" v-if="newPassword.length !=0 && !isValidPwd">
                        <div class="flex flex-row items-center"><Icononline v-if="validMsg.carac"/><Iconoffline v-else/><span class="ml-1 text-sm">{{ $t("sign.caractere") }}</span></div>
                        <div class="flex flex-row items-center"><Icononline v-if="validMsg.num"/><Iconoffline v-else/><span class="ml-1 text-sm">{{ $t("sign.chiffre") }}</span></div>
                        <div class="flex flex-row items-center"><Icononline v-if="validMsg.upper"/><Iconoffline v-else/><span class="ml-1 text-sm">{{ $t("sign.lettre") }}</span></div>
                        <div class="flex flex-row items-center"><Icononline v-if="validMsg.eightCarac"/><Iconoffline v-else/><span class="ml-1 text-sm">{{ $t("sign.eightcar") }}</span></div>
                    </div>

                    <div class="mt-6">
                        <label class="text-base " for="pwdconfirm">{{ $t("sign.pwdConfirm")}}</label>
                        <br>
                        <div 
                            class=" bg-white h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded flex flex-row"
                            :class="{ ' border-tex-2': submitted && errors.has('pwdconfirm') }">
                            <input 
                                class="p-3 w-11/12 py-2 leading-tight focus:outline-none focus:bg-white" 
                                id="pwdconfirm" 
                                name="pwdconfirm" 
                                 :type="pwdConfirmShow ? 'text' : 'password'"
                                v-validate="'required|confirmed:newpwd'"
                                data-vv-as="newpwd">
                            <Iconeye 
                                class="m-2" 
                                @click="pwdConfirmShow = !pwdConfirmShow"
                                v-if="!pwdConfirmShow"/>
                            <Iconeyeoff 
                                class="m-2" 
                                @click="pwdConfirmShow = !pwdConfirmShow"
                                v-if="pwdConfirmShow"/>
                        </div>
                    </div>

                    <button class="mt-6 text-white border h-search sm:h-minput bg-kbutton-1 hover:bg-kbutton-2 active:border active:border-kbutton-3 py-2 w-full rounded focus:outline-none">
                        <div v-show="!request">{{ $t("counterRecharge.btn")}}</div>
                        <LoaderCss class="ml-16" v-show="request" />
                    </button>
                    
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Iconeye from '../../assets/svg/icons/eye.svg?inline';
import Icononline from '../../assets/svg/icons/online.svg?inline';
import Iconoffline from '../../assets/svg/icons/offline.svg?inline';
import Iconeyeoff from '../../assets/svg/icons/eyeoff.svg?inline';
import Iconclose from '../../assets/svg/dashbIcons/close.svg?inline';
import LoaderCss from '@/components/LoaderCss'
import axios from 'axios'
export default {
    components:{
        Iconclose,
        Iconeye,
        Iconeyeoff,
        LoaderCss,
        Icononline,
        Iconoffline,
    },
    props: {
        changepwd:{
            type: Boolean,
            default: false
        },
        pwdSuccess:{
            type: Boolean,
            default: false
        },
        umId:{
            type: String,
            default: ''
        }
    },
    data() {
        return {
            oldPwdShow: false,
            pwdShow : false,
            pwdConfirmShow: false,
            submitted: false,
            changepwdData: this.changepwd,
            valider: false,
            request: false,
            oldPassword: '',
            newPassword: '',
            validMsg: {
                carac: false,
                num: false,
                upper: false,
                eightCarac: false
            },
            wrongPwd: false,
            success: this.pwdSuccess
        }
    },
    
    methods: {
        switchPwdType(){
            this.pwdType = this.pwdType === 'password' ? 'text' : 'password';
        },
        validatePassword(value){
            let eigh = /.{8,}/;
            let numreq = /[0-9]+/;
            let upp = /[A-Z]+/;
            let car = /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/;
            eigh.test(value) ? this.validMsg.eightCarac = true : this.validMsg.eightCarac = false;

            numreq.test(value) ? this.validMsg.num = true : this.validMsg.num = false;
            
            upp.test(value) ? this.validMsg.upper = true : this.validMsg.upper = false;
            
            car.test(value) ? this.validMsg.carac = true : this.validMsg.carac = false;
           
        },
        submitToChangePwd(){
            this.submitted = true;
            this.$validator.validate().then(valid => {
            if (valid) {
                this.request = true
                axios.put('/users/password/update', {
                    umId: this.umId,
                    oldPassword: this.oldPassword,
                    newPassword: this.newPassword,
                })
                .then(res=>{
                    console.log(res)
                    this.passpwdsuccess()
                    this.passchangepwd();
                })
                .catch(err=>{
                    this.request = false
                    console.log(err.response.data.message)
                    const message = err.response.data.message
                    if(message.code == 'old_password_mis') this.wrongPwd = true
                })
            }
            });
        },
        passchangepwd(){
            this.changepwdData = false;
            this.$emit('listenchangepwd',this.changepwdData);
        },
        passpwdsuccess(){
            this.success = true;
            this.$emit('listenpwdSuccess',this.success);
        },
    },
    computed: {
        isValidPwd(){
           if (this.validMsg.carac && this.validMsg.num && this.validMsg.upper && this.validMsg.eightCarac) return true
           return false
       }
    },
    watch: {
        newPassword(value){
            this.password = value;
            this.validatePassword(value);
        },
        oldPassword(){
            this.wrongPwd = false
        },
        changepwd: {
        immediate: true,
        handler(changepwd) {
        if (process.client) {
            if (changepwd) document.body.style.setProperty("overflow", "hidden");
            else document.body.style.removeProperty("overflow");
            }
        }
        }
    }
}
</script>

<style scoped>
.anime{
    animation: modal 400ms ease-out forwards;
}
@keyframes modal {
    0% {
        background-color: rgba(0, 0, 0, 0);
    }
    25% {
        background-color: rgba(0, 0, 0, 0.15);
    }
    50% {
        background-color: rgba(0, 0, 0, 0.3);
    }
    75% {
        background-color: rgba(0, 0, 0, 0.5);
    }
    100% {
        background-color: rgba(0, 0, 0, 0.75);
    }
}
</style>