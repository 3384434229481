<template>
    <div v-if="changename" class=" anime transform top-0 left-0 w-full h-full fixed "  :class="changename ? 'translate-x-0' : '-translate-x-full'">
        <div class="container mx-auto bg-white rounded w-10/12 sm:w-7/12 lg:w-5/12 mt-32">
            <span class="flex justify-end p-3"><Iconclose @click="passchangename"/></span>
            <div class=" container mx-auto p-12 pt-0">
                <form @submit.prevent="submitChangeName" class=" ">
                    <div class=" flex flex-col items-center justify-center">
                        <span class="mt-4 text-xl lg:text-3xl text-center font-semibold">{{ $t("account.nameChange",{c: 'C'})}}</span>
                    </div>

                    <div class="mt-4">
                        <label class="text-base" for="name">{{ $t("sign.lastname")}}</label>
                        <br>
                        <input 
                            class="p-3 h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded w-full py-2 leading-tight focus:outline-none focus:bg-white " 
                            v-validate="'required'"
                            id="name" 
                            name="name" 
                            type="text"
                            v-model="lastname"
                            :class="{ 'border border-tex-2': submitted && errors.has('name') }">
                    </div>

                    <div class="mt-4">
                        <label class="text-base" for="surname">{{ $t("sign.surname")}}</label>
                        <br>
                        <input 
                            class="p-3 h-minput border-1.5 border-kborder-500 hover:border-kinput-3 focus-within:border-kinput-2 rounded w-full py-2 leading-tight focus:outline-none focus:bg-white " 
                            v-validate="'required'"
                            id="surname" 
                            name="surname" 
                            type="text"
                            v-model="surname"
                            :class="{ 'border border-tex-2': submitted && errors.has('surname') }">
                    </div>

                    <button class="mt-6 text-white py-2 w-full h-search sm:h-minput bg-kbutton-1 hover:bg-kbutton-2 active:border active:border-kbutton-3 rounded focus:outline-none">
                        <div v-show="!request">{{ $t("counterRecharge.btn")}}</div>
                        <LoaderCss class="ml-16" v-show="request" />
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Iconclose from '../../assets/svg/dashbIcons/close.svg?inline';
import LoaderCss from '@/components/LoaderCss'
import axios from 'axios'
export default {
   
    props: {
        changename:{
            type: Boolean,
            default: false
        },
        nameSuccess:{
            type: Boolean,
            default: false
        },
        lastName:{
            type: String,
            default: ''
        },
        surName:{
            type: String,
            default: ''
        },
        userId:{
            type: String,
            default: ''
        }
    },
    components: {
        Iconclose,
        LoaderCss
    },
    data() {
        return {
            request: false,
            lastname: this.lastName,
            surname: this.surName,
            submitted: false,
            changenameData: this.changename,
            valider: false,
            success: this.nameSuccess
        }
    },
    
    methods: {
        submitChangeName(){
            this.submitted = true;
            this.$validator.validate().then(valid => {
            if (valid) {
                this.request = true
                axios.put('/users/usernameUpdate', {
                    userId: this.userId,
                    firstName: this.surname,
                    lastName: this.lastname,
                })
                .then(res=>{
                    console.log(res)
                    const token = localStorage.getItem('token')
                    axios.post(`/users/check`, {
                        token: token
                    })
                    .then(async res =>{
                        const userData = res.data
                        this.$store.commit('mutAuthUser', userData)
                        await this.passnamesuccess()
                        this.passchangename();
                    })
                    .catch(err =>{
                        console.log(err)
                    })

                    
                })
                .catch(err=>{
                    this.request = false
                    console.log(err.response.data.message)
                })
            }
            });
        },
        passchangename(){
            this.changenameData = false;
            this.$emit('listenchangename',this.changenameData);
        },
        passnamesuccess(){
            this.success = true;
            this.$emit('listennameSuccess',this.success);
        },
    },
    watch: {
    changename: {
      immediate: true,
      handler(changename) {
       if (process.client) {
          if (changename) document.body.style.setProperty("overflow", "hidden");
          else document.body.style.removeProperty("overflow");
        }
      }
    }
    }
}
</script>

<style scoped>
.anime{
    animation: modal 400ms ease-out forwards;
}
@keyframes modal {
    0% {
        background-color: rgba(0, 0, 0, 0);
    }
    25% {
        background-color: rgba(0, 0, 0, 0.15);
    }
    50% {
        background-color: rgba(0, 0, 0, 0.3);
    }
    75% {
        background-color: rgba(0, 0, 0, 0.5);
    }
    100% {
        background-color: rgba(0, 0, 0, 0.75);
    }
}
</style>