<template>
    <div class="bg-lg h-full ffamily-pro px-8 sm:px-20 pt-10 pb-64">
        <div v-show="passwordChanged" class="flex justify-end">
            <toastSuccess class="" :position ="'-mt-8'">
                <p>{{ $t("account.pwdChangeSuccess")}}</p>
            </toastSuccess>
        </div>
        <div v-show="nameChanged" class="flex justify-end">
            <toastSuccess class="" :position ="'-mt-8'">
                <p>{{ $t("account.nameChangeSuccess")}}</p>
            </toastSuccess>
        </div>
        <div class="container mx-auto p-4 sm:p-8 bg-white">
            <div class="font-semibold">
                <div class="text-tex-6 text-2xl mb-5 font-bold">
                    {{ $t("account.title")}}
                </div>
                <hr style="color:#C2CBD6">
                <div class="flex flex-col mt-5">
                    <span class="text-tex-5 text-sm">{{ $t("account.name")}}</span>
                    <span class="flex flex-col md:flex-row md:items-center">
                        <span style="text-transform: capitalize;">{{ user.firstName }} {{ user.lastName }}</span>
                        <button @click="changename=true" class="md:ml-3 text-sm text-tex-4 text-left focus:outline-none font-semibold underline">{{ $t("account.nameChange",{c: 'c'})}}</button>
                    </span>
                </div>
                <div class="flex flex-col mt-5">
                    <span class="text-tex-5 text-sm">Email</span>
                    <span class="flex flex-col md:flex-row md:items-center">
                        <span>{{ user.email }}</span>
                        <!-- <button @click="changemail=true" class="md:ml-3 text-sm text-tex-4 text-left focus:outline-none font-semibold underline">{{ $t("account.mailChange",{c: 'c'})}}</button> -->
                    </span>
                </div>
                <div class="flex flex-col mt-5 mb-4 text-sm">
                    <span class="text-tex-5">{{ $t("sign.pwd")}}</span>
                    <button  @click="changepwd=true" class="text-tex-4 text-left focus:outline-none font-semibold underline">{{ $t("account.pwdChange",{c: 'c'})}}</button>
                </div>

            </div>
        </div>
        <KdPwdChangeModal :pwdSuccess="pwdSuccess" :changepwd="changepwd" :umId="user.umId" v-if="changepwd" @listenchangepwd ="changepwd = $event"  @listenpwdSuccess ="pwdSuccess = $event"/>
        <KdEmailChangeModal :changemail="changemail" v-if="changemail"/>
        <KdNameChangeModal :nameSuccess="nameSuccess" :changename="changename" :surName="user.firstName" :lastName="user.lastName" :userId="user.id" v-if="changename" @listenchangename ="changename = $event" @listennameSuccess ="nameSuccess = $event"/>
    </div>
</template>

<script>
import KdPwdChangeModal from './KdPwdChangeModal'
import KdEmailChangeModal from './KdEmailChangeModal'
import KdNameChangeModal from './KdNameChangeModal'
import toastSuccess from '@/components/toastComponents/toastSuccess'
export default {
    data() {
        return {
            changepwd:false,
            changemail: false,
            changename: false,
            nameChanged: false,
            passwordChanged: false,
            nameSuccess: false,
            pwdSuccess: false,
        }
    },
    computed: {
        user(){
            return this.$store.getters.getAuthUser
        }
    },
    watch: {
        nameSuccess(){
            if(this.nameSuccess == true){
                this.nameChanged = !this.nameChanged;
                const self = this;
                setTimeout(function () {
                    self.nameChanged = false;
                    self.nameSuccess = false
                }, 3000);
            }
            
        },
        pwdSuccess(){
            if(this.pwdSuccess == true){
                this.passwordChanged = !this.passwordChanged;
                const self = this;
                setTimeout(function () {
                    self.passwordChanged = false;
                    self.pwdSuccess = false
                }, 3000);
            }
            
        }
    },
    components: {
        KdPwdChangeModal,
        toastSuccess,
        KdEmailChangeModal,
        KdNameChangeModal
    }
}
</script>

<style scoped>
 .bg-lg {
    background-image: url(../../assets/svg/SBEEA1.svg);
    background-repeat: no-repeat;
    background-position: left bottom  ;
    background-size: cover;
}


@media (min-width: 640px) {
  .bg-lg {
    background-image: url(../../assets/svg/dashbIcons/background2lg.svg);
    background-repeat: no-repeat;
    background-position: left bottom  ;
    background-size: cover;
  }
}
</style>